<template>
  <div>    
    <Form />
  </div>
</template>

<script>
export default {
  components: {
    Form: () => import("./components/Form.vue"),
  },
};
</script>

<style>
</style>